import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

import { getAnonymousUserIdCookie, setAnonymousUserIdCookie } from './cookies'
import {
  STORAGE_KEY,
  EXTENSION_MESSAGE_TYPES,
  GobyApiCookieType,
} from '../utils/enums'
import * as localStorage from '../utils/localStorage'
import { createMessage } from '../utils/messages'

// For use on Webflow
function isOnMarketingSite(): boolean {
  if (typeof window === 'undefined') {
    return false
  }
  let url: URL | undefined
  try {
    url = new URL(window.location.href)
  } catch {
    return false
  }
  // We're using http://webflow:5000/static/fake_webflow_page.html locally
  return (
    url.hostname === 'webflow' ||
    url.hostname === 'www.blockpartyapp.com' ||
    url.hostname === 'staging.blockpartyapp.com'
  )
}

// Get the anonymous user ID
export async function getAnonymousUserId(): Promise<string | undefined> {
  if (isOnMarketingSite()) {
    // If we're on https://www.blockpartyapp.com or http://webflow:5000, get it
    // from a cookie directly on that domain
    const anonymousUserId: string | undefined = Cookies.get(
      GobyApiCookieType.ANONYMOUS_USER_ID,
    )
    if (!anonymousUserId) {
      return undefined
    }
    return anonymousUserId
  }

  if (typeof chrome !== 'undefined' && chrome.cookies) {
    // If we're within the extension and the Chrome cookies API is available,
    // get it directly from a cookie on the extension server
    return getAnonymousUserIdCookie()
  }

  if (typeof chrome !== 'undefined' && chrome.runtime) {
    // If we're within the extension, get it from a cookie on
    // https://www.privacypartyapp.com via the Chrome messaging API
    const message = createMessage(EXTENSION_MESSAGE_TYPES.GET_ANONYMOUS_USER_ID)
    const response = await chrome.runtime.sendMessage(message)
    if (response) {
      return response.anonymousUserId
    }
  }
  return undefined
}

// Set the anonymous user ID
export async function setAnonymousUserId(
  anonymousUserId: string,
): Promise<void> {
  if (isOnMarketingSite()) {
    // If we're on https://www.blockpartyapp.com or http://webflow:5000, set it
    // on a cookie directly on that domain
    Cookies.set(GobyApiCookieType.ANONYMOUS_USER_ID, anonymousUserId, {
      // Specify domain so can be shared with the extension server
      domain: window.location.host,
      expires: 365,
      sameSite: 'Lax',
      secure: process.env.NODE_ENV !== 'development',
    })
  } else if (typeof chrome !== 'undefined' && chrome.cookies) {
    // If we're within the extension and the Chrome cookies API is available,
    // set it on a extension server cookie directly
    setAnonymousUserIdCookie(anonymousUserId)
  } else if (typeof chrome !== 'undefined' && chrome.runtime) {
    // If we're within the extension, set it on a cookie on
    // the extension server via the Chrome messaging API
    const message = createMessage(
      EXTENSION_MESSAGE_TYPES.SET_ANONYMOUS_USER_ID,
      { anonymousUserId },
    )
    await chrome.runtime.sendMessage(message)
  }
}

export async function getExtensionInstallationId(): Promise<
  string | undefined
> {
  return localStorage.getSingle(STORAGE_KEY.EXTENSION_INSTALLATION_ID)
}

export async function setExtensionInstallationId() {
  const extensionInstallInstanceIdItems = await chrome.storage.local.get([
    STORAGE_KEY.EXTENSION_INSTALLATION_ID,
  ])

  let extensionInstallId =
    extensionInstallInstanceIdItems?.[STORAGE_KEY.EXTENSION_INSTALLATION_ID]
  if (!extensionInstallId) {
    extensionInstallId = uuidv4()
    await chrome.storage.local.set({
      [STORAGE_KEY.EXTENSION_INSTALLATION_ID]: extensionInstallId,
    })
  }
  return extensionInstallId
}
